const customSelect = document.querySelector(".customSelect");
const selectButton = document.querySelector(".selectButton");
const selectedValue = document.querySelector(".selectedValue");
const selectRequiredInput = document.querySelector(".selectRequiredInput");
const selectDropdown = document.querySelector(".selectDropdown");
const companyName = document.querySelector(".companyName");

const toggleCompanyNameDisplay = (idOfSelectedOption) => {
  if (idOfSelectedOption === "11" || idOfSelectedOption === "14") {
    companyName.classList.add("hide");
    companyName.children[1].removeAttribute("required");
  } else {
    companyName.classList.remove("hide");
    companyName.children[1].setAttribute("required", "true");
  }
}

selectButton.addEventListener("click", () => {
  customSelect.classList.toggle("active");
  selectButton.setAttribute("aria-expanded", selectButton.getAttribute("aria-expanded") === "true" ? "false" : "true");
}); 

document.addEventListener("DOMContentLoaded", () => {
  const data = JSON.parse(localStorage.genieLanding || '{}');

  fetch('/api/v1/landing/claims/positions', { 
    method: 'get',
    headers: {
      "Accept-Language": data.lang ? data.lang.toLowerCase() : "en"
    }
  })
    .then((res) => res.json())
    .then((positions) => {
      positions.forEach((position) => {
        selectDropdown.innerHTML = `${selectDropdown.innerHTML}
<li role="option">
  <input type="radio" id="${position.id}" name="position">
  <label for="${position.id}">${position.name}</label>
</li>`;
      })

      document.querySelectorAll(".selectDropdown li").forEach((option) => {
        function handler(e) {
          if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
            selectedValue.textContent = this.children[1].textContent;
            selectRequiredInput.value = this.children[0].id;
            customSelect.classList.remove("active");
            toggleCompanyNameDisplay(this.children[0].id)
          }
        }
      
        option.addEventListener("click", handler);
      });
    })  
});
